import React, { useState } from "react";
import "./SignupPopup.css";

var options = {
  managementsoftware: ["ClickUp", "monday.com", "Asana"],
  platform: ["Windows", "Mac", "iOS/Android"],
};

const SignupPopup = ({ isOpen, onClose, onSubmit }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    managementsoftware: [],
    platform: [],
  });

  const handleOptionChange = (category, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [category]: prev[category].includes(value)
        ? prev[category].filter((item) => item !== value)
        : [...prev[category], value],
    }));
  };

  const handleDone = () => {
    onSubmit(selectedOptions);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">How would you use TickerTime.io?</div>
        <div className="popup-content">
          <ContentSection
            title="Management Software"
            options={options.managementsoftware}
            selectedOptions={selectedOptions.managementsoftware}
            onChange={handleOptionChange}
          />
          <ContentSection
            title="Platform"
            options={options.platform}
            selectedOptions={selectedOptions.platform}
            onChange={handleOptionChange}
          />
        </div>
        <div className="popup-footer">
          <button onClick={handleDone}>Done</button>
        </div>
      </div>
    </div>
  );
};

function ContentSection({ title, options, selectedOptions, onChange }) {
  return (
    <div className="content-section">
      <h3>{title}</h3>
      {options.map((option) => (
        <label className="custom-checkbox" key={option}>
          <input
            type="checkbox"
            name={title}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={() =>
              onChange(title.toLowerCase().replace(/ /g, ""), option)
            }
          />
          {option}
        </label>
      ))}
    </div>
  );
}

export default SignupPopup;
