import React, { useState } from "react";
import axios from "axios";
import SignupPopup from "./SignupPopup";
import ConfirmationPopup from "./ConfirmationPopup";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <HeroBanner />
      <MailingList />
    </div>
  );
}

function Header() {
  return (
    <header className="app-header">
      <span className="timer-title">TickerTime.io</span>
      <img src="/logo192.png" alt="logo" className="app-logo" />
    </header>
  );
}

function HeroBanner() {
  return (
    <div className="hero-banner-background">
      <div className="left-half">
        <div className="top-left">
          <h1 className="introduction-header">Introducing TickerTime.io</h1>
          <p className="introduction-text">
            Helping you to gain <strong>more</strong> time, but keeping an eye
            on the time you are using now.
          </p>
        </div>
        <div className="bottom-left">
          <BottomLeftSection
            imageSrc="clickup_icon_white.png"
            imageAlt="ClickUp Logo"
          >
            Seamless integration with ClickUp so you can keep on top of your
            work, while still making sure your schedule isn't missed.
          </BottomLeftSection>
          <BottomLeftSection imageSrc="logo192.png" imageAlt="ClickUp Logo">
            Keep on top of personal tasks, as well as tracking assigned tasks,
            so nothing is missed.
          </BottomLeftSection>
        </div>
      </div>
      <div className="right-half">
        <img
          className="screenshot-image"
          src="screenshot_01.jpg"
          alt="App Screenshot"
        />
      </div>
    </div>
  );
}

function BottomLeftSection(props) {
  return (
    <div className="bottom-left-section">
      <div className="section-image-container">
        <img
          src={props.imageSrc}
          alt={props.imageAlt}
          className="section-image"
        />
      </div>
      <div className="section-text-container">
        <p className="section-text">{props.children}</p>
      </div>
    </div>
  );
}

function MailingList() {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [waitlistEmail, setWaitlistEmail] = useState([]);
  const [emailValid, setEmailValid] = useState(true);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/
      );
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setWaitlistEmail(email);
  };

  const handleOpenPopup = async () => {
    if (!validateEmail(waitlistEmail)) {
      setEmailValid(false);
      return;
    }

    setEmailValid(true);

    try {
      const response = await axios.post(
        `http://tickertime-backend-env.eba-xjhv5rwq.ap-southeast-2.elasticbeanstalk.com/api/v1/check_email`,
        {
          email: waitlistEmail,
        }
      );
      if (response.data.email_exists) {
        alert("Email already exists on the waitlist!");
      } else {
        setPopupOpen(true);
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  const handleSubmit = (selectedOptions) => {
    SubmitNewWaitlist(selectedOptions, waitlistEmail);
    setPopupOpen(false);
    setConfirmationOpen(true);
  };
  const handleConfirmationClose = () => setConfirmationOpen(false);

  return (
    <main className="mailing-list">
      <h2 className="launch-title">Launching soon!</h2>
      <div className="email-signup">
        <input
          type="email"
          placeholder="Enter your email address"
          className={`email-input ${
            !emailValid ? "invalid-input" : "valid-input"
          }`}
          value={waitlistEmail}
          onChange={handleEmailChange}
        />
        <button onClick={handleOpenPopup} className="waitlist-btn">
          JOIN WAITLIST
        </button>
        <SignupPopup
          isOpen={isPopupOpen}
          onClose={() => setPopupOpen(false)}
          onSubmit={handleSubmit}
        />
        {isConfirmationOpen && (
          <ConfirmationPopup onClose={handleConfirmationClose} />
        )}
      </div>
    </main>
  );
}

function SubmitNewWaitlist(selectedOptions, waitlistEmail) {
  console.log("Selected options: ", selectedOptions);
  console.log("Email: ", waitlistEmail);

  // Send data to server
  // Prepare the data based on the selected options and email
  const data = {
    email: waitlistEmail,
    click_up: selectedOptions.managementsoftware.includes("ClickUp"),
    monday: selectedOptions.managementsoftware.includes("monday.com"),
    asana: selectedOptions.managementsoftware.includes("Asana"),
    windows: selectedOptions.platform.includes("Windows"),
    mac: selectedOptions.platform.includes("Mac"),
    ios_android: selectedOptions.platform.includes("iOS/Android"),
  };

  console.log("Sending data to server: ", data);

  // Send data to the server using fetch API
  fetch(
    "http://tickertime-backend-env.eba-xjhv5rwq.ap-southeast-2.elasticbeanstalk.com/api/v1/email_list",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      // Additional code to handle success, such as updating the UI
    })
    .catch((error) => {
      console.error("Error:", error);
      // Additional code to handle errors, such as displaying an error message
    });
}

export default App;
