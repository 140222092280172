import React from "react";
import "./ConfirmationPopup.css";

const ConfirmationPopup = ({ onClose }) => {
  return (
    <div className="confirmation-overlay">
      <div className="confirmation-container">
        <p>Thank you for signing up!</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
